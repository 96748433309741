import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';

import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';
import PageContent from '../styled/PageContent';
import AboutPageBody from '../components/AboutPage/AboutPageBody';
import Layout from '../layouts/index';

class AboutPage extends React.Component {
  render() {
    const { gatsbyUrl, pythonUrl, brand, affiliateUrl } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <div>
          <PageHeader
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            brand={brand}
          />
          <PageContent>
            <AboutPageBody
              gatsbyUrl={gatsbyUrl}
              pythonUrl={pythonUrl}
              brand={brand}
            />
          </PageContent>
          <PageFooter
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            brand={brand}
            location={location}
            affiliateUrl={affiliateUrl}
          />
        </div>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        brand {
          name
          nameCom
          twitter
          facebook
        }
        gatsbyUrl
        pythonUrl
        affiliateUrl
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <AboutPage data={data} location={location} />}
  />
);
