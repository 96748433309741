import React from 'react';
import { withNamespaces } from 'react-i18next';

import './AboutPageBody.scss';
import NotAMemberComponent from '../NotAMemberComponent';
import Api from '../../utils/api';
import SiteTitle from '../PageHeaderTitle';

import maskIcon from '../../assets/img/mask.svg';

class AboutPageBody extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  render() {
    const { t, brand, i18n } = this.props;
    const currentDate = (new Date()).toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    return (
      <div className='aboutPage'>
        <SiteTitle brandName={brand.name} title={t('page_about:about')} />
        <div className='about-wrapper about-wrapper_color_gray about-wrapper_color_gray about-wrapper_borderBottom aboutPage__block aboutPage__block_wide'>
          <div className='about-header'>
            <div className='about-innerWrapper'>
              <div className='about-header__grid'>
                <div className='about-header__image'>
                  <img src={maskIcon} alt={brand.name} />
                </div>
                <h1 className='about-title_h1 about-header__title'>
                  {t('page_about:header_h1')}
                </h1>
                <div
                  className='about-header__text'
                  dangerouslySetInnerHTML={{ __html: t('page_about:header') }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='about-wrapper aboutPage__block'>
          <div className='about-innerWrapper'>
            <div className='about-block'>
              <div
                className='about-block__title about-title_h2'
                dangerouslySetInnerHTML={{ __html: t('page_about:title_1') }}
              />
              <div
                className='about-block__text about-text'
                dangerouslySetInnerHTML={{ __html: t('page_about:text_1') }}
              />
            </div>
          </div>
        </div>
        <div className='about-wrapper aboutPage__block'>
          <div className='about-innerWrapper'>
            <div className='about-block'>
              <div
                className='about-title_h2 about-block__title'
                dangerouslySetInnerHTML={{ __html: t('page_about:title_2') }}
              />
              <div
                className='about-block__text about-text'
                dangerouslySetInnerHTML={{ __html: t('page_about:text_2') }}
              />
            </div>
          </div>
        </div>
        <div className='about-wrapper aboutPage__block'>
          <div className='about-innerWrapper'>
            <div className='about-block'>
              <div
                className='about-title_h2 about-block__title'
                dangerouslySetInnerHTML={{ __html: t('page_about:title_3') }}
              />
              <div
                className='about-block__text about-text'
                dangerouslySetInnerHTML={{ __html: t('page_about:text_3') }}
              />
            </div>
          </div>
        </div>
        <div className='about-wrapper aboutPage__block'>
          <div className='about-innerWrapper'>
            <div className='about-block'>
              <div
                className='about-title_h2 about-block__title'
                dangerouslySetInnerHTML={{ __html: t('page_about:title_4') }}
              />
              <div
                className='about-block__text about-text'
                dangerouslySetInnerHTML={{ __html: t('page_about:text_4') }}
              />
            </div>
          </div>
        </div>
        <div className='about-wrapper aboutPage__block'>
          <div className='about-innerWrapper'>
            <div className='about-block'>
              <div
                className='about-title_h2 about-block__title'
                dangerouslySetInnerHTML={{ __html: t('page_about:title_5') }}
              />
              <div
                className='about-block__text about-text'
                dangerouslySetInnerHTML={{ __html: t('page_about:text_5') }}
              />
            </div>
          </div>
        </div>
        <div>
          <div className='about-wrapper'>
            <div className='about-innerWrapper'>
              <div className='about-block'>
                <div
                  className='about-block__title about-title_h2'
                  dangerouslySetInnerHTML={{ __html: t('page_about:title_6') }}
                />
                <div
                  className='about-block__text about-text'
                  dangerouslySetInnerHTML={{ __html: t('page_about:text_6', { currentDate }) }}
                />
              </div>
            </div>
          </div>
        </div>
        <NotAMemberComponent
          brand={brand.name}
          text={t('page_about:not_a_member')}
          buttonText={t('page_about:get_anonine')}
          orderUrl={this.api.orderUrl()}
        />
      </div>
    );
  }
}

export default withNamespaces()(AboutPageBody);
